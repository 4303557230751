import { Box, Stack } from "@mui/system";
import React from "react";

const LoginLogos = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <Box
        sx={{
          width: {
            xs: "200px",
            md: "250px",
          },
        }}
      >
        <img src="/assets/images/logo2025-colored.png" alt="logo" loading="lazy" width="100%" />
      </Box>
      <Box
        sx={{
          width: {
            xs: "150px",
            md: "180px",
          },
        }}
      >
        <img src="/assets/images/JIF-logo.png" alt="jif=logo" loading="lazy" width="100%" />
      </Box>
    </Stack>
  );
};

export default LoginLogos;
