import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import theme from "../../theme/theme";
const useStyles = makeStyles(() => ({
  input: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1),
    height: "3rem",
    "&:focus": {
      outline: "none",
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  wrapper: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    "& input": {
      width: "100%",
    },
  },
}));
const MeetingDatePicker = ({ label, handleChange, value }) => {
  const classes = useStyles();

  const minDateTime = new Date("2025-05-12T00:00:00");
  const maxDateTime = new Date("2025-05-14T23:59:59");
  // Function to set time to 8:00 AM when a new date is selected
  // const handleDateChange = (date) => {
  //   if (date) {
  //     const adjustedDate = new Date(date);
  //     adjustedDate.setHours(8, 0, 0, 0); // Set to 8:00 AM
  //     handleChange(adjustedDate); // Call parent handler with updated date
  //   }
  // };


  return (
    <div className={classes.wrapper}>
      <DatePicker
        selected={value}
       onChange={handleChange} // Custom handler to set time to 8 AM
        minDate={minDateTime}
        maxDate={maxDateTime}
        className={classes.input}
        timeInputLabel="Time:"
        showTimeInput
        dateFormat="MMMM d, yyyy h:mm aa"
        placeholderText={label}
        required
      />
    </div>
  );
};

export default MeetingDatePicker;
